import { listBillingProducts } from "@data/billing";
import { listCrmProducts } from "@data/crm";
import { listEventTypes } from "@data/events";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import {
  BillingProductResponseData,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  RuleConditionDetailResponseData,
  RuleConditionResourceResponseData,
} from "@models/api";
import {
  ComparableEntityTraitTypes,
  EntityTraitType,
  EntityType,
} from "@models/entityTrait";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriods,
} from "@models/feature";
import { PlanType } from "@models/plan";
import { listCompanies } from "@modules/companies/queries";
import { listCompanyUsers } from "@modules/companyUsers/queries";
import { listPlans } from "@modules/plans/queries";
import { listEntityTraitDefinitions } from "@modules/settings/queries/entityTraits";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { BillingProductOptionLabel } from "@ui/BillingProductOptionLabel";
import { Button } from "@ui/Button";
import { titlecase } from "@utils/strings";
import { useEffect, useState } from "react";
import Option from "react-select/dist/declarations/src/components/Option";
import * as Yup from "yup";
import { ConditionLogicalOperator, OR_CONDITION_MAX_LENGTH } from "./consts";
import { EntityTraitLabel } from "./EntityTraitLabel";
import TraitValueInput from "./TraitValueInput";

const allOperators = [
  { value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq, label: "==" },
  { value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne, label: "!=" },
  { value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gt, label: ">" },
  {
    value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gte,
    label: ">=",
  },
  { value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lt, label: "<" },
  {
    value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lte,
    label: "<=",
  },
];

type Option = {
  value: string;
  label: string;
  resource?: RuleConditionResourceResponseData;
};

export interface RuleConditionProps {
  condition: RuleConditionDetailResponseData;
  field: string;
  conditionsLength: number;
  logicalOperator: ConditionLogicalOperator;
  permittedConditionTypes?: CreateOrUpdateConditionRequestBodyConditionTypeEnum[];
  permittedTraitEntityType?: EntityType;
  readonly?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
  onAddAndCondition?: () => void;
  onAddOrCondition?: () => void;
  onRemove?: () => void;
  // TODO: Think how to do it in a better way
  disableAnd?: boolean;
  disableOr?: boolean;
}

export const RuleConditionRowValidationSchema = Yup.object().shape({
  conditionType: Yup.mixed()
    .oneOf(Object.values(CreateOrUpdateConditionRequestBodyConditionTypeEnum))
    .required("Criteria is required"),
  operator: Yup.mixed()
    .oneOf(Object.values(CreateOrUpdateConditionRequestBodyOperatorEnum))
    .required("Operator is required")
    .test(
      "operatorByConditionType",
      "Operator is not valid for this condition type",
      (value: any, context) => {
        const conditionType = context.parent
          .conditionType as CreateOrUpdateConditionRequestBodyConditionTypeEnum;

        switch (conditionType) {
          case CreateOrUpdateConditionRequestBodyConditionTypeEnum.BasePlan:
            return [
              CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
              CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
              CreateOrUpdateConditionRequestBodyOperatorEnum.IsEmpty,
              CreateOrUpdateConditionRequestBodyOperatorEnum.NotEmpty,
            ].includes(value);
          case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company:
          case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan:
          case CreateOrUpdateConditionRequestBodyConditionTypeEnum.User:
            return [
              CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
              CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
            ].includes(value);
          case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait: {
            const traitType = context.parent.trait?.traitType;
            if (
              [
                EntityTraitType.Boolean,
                EntityTraitType.String,
                EntityTraitType.URL,
              ].includes(traitType)
            ) {
              return [
                CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
                CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
              ].includes(value);
            }
            return true;
          }
          default:
            return true;
        }
      },
    ),
  resourceIds: Yup.array()
    .of(Yup.string())
    .when("conditionType", ([conditionType], schema) => {
      return [
        CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
        CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan,
        CreateOrUpdateConditionRequestBodyConditionTypeEnum.User,
      ].includes(conditionType)
        ? schema.min(1, "Select at least one resource")
        : schema.min(0);
    }),
  eventSubtype: Yup.string().when(
    "conditionType",
    ([conditionType], schema) => {
      return conditionType ===
        CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric
        ? schema.required("Usage metric is required")
        : schema.nullable();
    },
  ),
  trait: Yup.object().when("conditionType", ([conditionType], schema) => {
    return conditionType ===
      CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait
      ? schema.required("Trait is required")
      : schema;
  }),
  traitValue: Yup.string().when("conditionType", ([conditionType], schema) => {
    return conditionType ===
      CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait
      ? schema.required("Trait value is required")
      : schema;
  }),
});

const allConditionTypes = [
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
    label: "Company",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.User,
    label: "User",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.BasePlan,
    label: "Base Plan",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan,
    label: "Base Plan or Add-on",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait,
    label: "Trait",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric,
    label: "Usage",
  },
  {
    value: CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct,
    label: "Billing Product",
  },
];

export const RuleConditionRow = ({
  condition,
  field,
  conditionsLength,
  logicalOperator,
  setFieldValue,
  readonly = false,
  onAddAndCondition,
  onAddOrCondition,
  onRemove,
  permittedConditionTypes,
  permittedTraitEntityType,
  disableAnd = false,
  disableOr = false,
}: RuleConditionProps) => {
  const stripeIntegrationFlag = useSchematicFlag("stripe-integration-flag", {
    fallback: true,
  });

  const crmHubspotIntegrationFlag = useSchematicFlag(
    "crm-hubspot-integration-flag",
  );

  let conditionTypes = permittedConditionTypes
    ? allConditionTypes.filter((c) => permittedConditionTypes.includes(c.value))
    : allConditionTypes;
  conditionTypes = conditionTypes.filter((condition) => {
    if (
      condition.value ===
      CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct
    ) {
      return stripeIntegrationFlag;
    }

    return true;
  });

  const metricPeriodOptions = ConditionMetricPeriods.filter((period) => {
    if (period === CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Billing) {
      return stripeIntegrationFlag;
    }
    return true;
  }).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const [resourcesLabel, setResourcesLabel] = useState("resources");
  useEffect(() => {
    switch (condition.conditionType) {
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company:
        setResourcesLabel("companies");
        break;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.BasePlan:
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan:
        setResourcesLabel("plans");
        break;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct:
        setResourcesLabel("billing_product");
        break;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.CrmProduct:
        setResourcesLabel("crm_product");
        break;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.User:
        setResourcesLabel("users");
        break;
      default:
        setResourcesLabel("resources");
        break;
    }
  }, [condition]);

  const [validOperators, setValidOperators] = useState<Option[]>([]);
  useEffect(() => {
    switch (condition.conditionType) {
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.BasePlan:
        setValidOperators([
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
            label: "is one of",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
            label: "is not one of",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.IsEmpty,
            label: "is empty",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.NotEmpty,
            label: "is not empty",
          },
        ]);
        return;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company:
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan:
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct:
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.User:
        setValidOperators([
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
            label: "is one of",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
            label: "is not one of",
          },
        ]);
        return;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait:
        if (
          condition.trait &&
          !ComparableEntityTraitTypes.includes(
            condition.trait.traitType as EntityTraitType,
          )
        ) {
          setValidOperators([
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
              label: "is equal to",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
              label: "is not equal to",
            },
          ]);

          return;
        }

        if (condition.trait?.traitType === EntityTraitType.Date) {
          setValidOperators([
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gt,
              label: "is after",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gte,
              label: "is on or after",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lte,
              label: "is on or before",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lt,
              label: "is before",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
              label: "is on",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
              label: "is not on",
            },
          ]);

          return;
        }

        if (
          condition.trait?.traitType === EntityTraitType.Currency ||
          condition.trait?.traitType === EntityTraitType.Number
        ) {
          setValidOperators([
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
              label: "is equal",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
              label: "is not equal to",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gt,
              label: "is more than",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gte,
              label: "is more than or equal to",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lt,
              label: "is less than",
            },
            {
              value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lte,
              label: "is less than or equal to",
            },
          ]);

          return;
        }

        setValidOperators(allOperators);
        return;
      case CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric:
        setValidOperators([
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
            label: "is equal",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Ne,
            label: "is not equal to",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gt,
            label: "is more than",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Gte,
            label: "is more than or equal to",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lt,
            label: "is less than",
          },
          {
            value: CreateOrUpdateConditionRequestBodyOperatorEnum.Lte,
            label: "is less than or equal to",
          },
        ]);

        return;
      default:
        setValidOperators(allOperators);
        return;
    }
  }, [condition]);

  return (
    <div className="flex relative pr-8 pl-16">
      <div className="bg-gray-300 text-blue-400 w-[52px] h-[52px] rounded-full flex items-center justify-center text-sm font-bold font-body tracking-widest absolute left-0 translate-x-[-50%] top-[46px] translate-y-[-50%]">
        {logicalOperator}
      </div>
      <div className="flex space-x-2 justify-between flex-1">
        {/* Condition Type Select */}
        <FormikSelect
          options={conditionTypes}
          label="Criteria"
          name={`${field}.conditionType`}
          placeholder="Select criteria..."
          className="min-w-fit max-w-[90px]"
          selectedOption={conditionTypes.find(
            (c) => c.value === condition.conditionType,
          )}
          disabled={readonly}
        />

        {/* Trait Select */}
        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait && (
          <FormikAsyncSelect
            label="Trait"
            name={`${field}.trait`}
            loadOptions={listEntityTraitDefinitions}
            loadOptionsMappers={{
              requestFilter: { entityType: permittedTraitEntityType },
              mapperFunction: (trait) => ({
                value: trait,
                label: <EntityTraitLabel entityTrait={trait} />,
              }),
              resultsFilter: (trait) => !!trait.id,
            }}
            className="w-full flex-1 min-w-fit"
            selectedOption={
              condition.trait && {
                value: condition.trait.id,
                label: <EntityTraitLabel entityTrait={condition.trait} />,
              }
            }
            onChange={(option) => {
              setFieldValue?.(`${field}.traitValue`, "");
              setFieldValue?.(`${field}.traitId`, option?.value?.id);
            }}
            defaultOptions
            disabled={readonly}
          />
        )}

        {/* Metric (Event Subtype) Select */}
        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric && (
          <FormikAsyncSelect
            label="Metric"
            name={`${field}.eventSubtype`}
            loadOptions={listEventTypes}
            loadOptionsMappers={{
              mapperFunction: (eventType) => ({
                value: eventType.eventSubtype,
                label: eventType.eventSubtype,
              }),
            }}
            selectedOption={
              condition.eventSubtype && {
                value: condition.eventSubtype,
                label: condition.eventSubtype,
              }
            }
            defaultOptions
            disabled={readonly}
          />
        )}

        {/* Operator Select */}
        <FormikSelect
          className="max-w-[90px] min-w-fit"
          disabled={readonly}
          label="Operator"
          name={`${field}.operator`}
          options={validOperators}
          isClearable={false}
          placeholder="Select operator..."
          selectedOption={validOperators.find(
            (c) => c.value === condition.operator,
          )}
        />

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Trait &&
          condition.trait && (
            <>
              <TraitValueInput
                disabled={readonly}
                label="Value"
                name={`${field}.traitValue`}
                setFieldValue={setFieldValue}
                trait={condition.trait}
                value={condition.traitValue}
              />
            </>
          )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Metric && (
          <>
            <FormikControl
              className="max-w-[150px]"
              control="input"
              disabled={readonly}
              label="Value"
              name={`${field}.metricValue`}
              setFieldValue={setFieldValue}
              type="number"
            />

            <FormikSelect
              disabled={readonly}
              label="Period"
              name={`${field}.metricPeriod`}
              options={metricPeriodOptions}
              placeholder="Select period..."
              selectedOption={metricPeriodOptions.find(
                (c) => c.value === condition.metricPeriod,
              )}
            />
          </>
        )}

        {/* Entity select */}
        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company && (
          <FormikAsyncSelect
            className="flex-1"
            defaultOptions
            disabled={readonly}
            isMulti
            label={titlecase(resourcesLabel)}
            loadOptions={listCompanies}
            loadOptionsMappers={{
              requestFilter: {
                limit: 10,
              },
              mapperFunction: (company) => ({
                value: company.id,
                label: company.name,
                resource: company,
              }),
            }}
            name={`${field}.resourceIds`}
            placeholder={`Type to select ${resourcesLabel}...`}
            onChange={(options: Option[]) => {
              const resourceIds = (condition.resources || []).map((r) => r.id);
              setFieldValue?.(`${field}.resources`, [
                ...(condition.resources || []),
                ...options
                  .filter((o) => !resourceIds.includes(o.value))
                  .map((o) => o.resource),
              ]);
            }}
            selectedOption={(condition.resources || [])
              .filter(Boolean)
              .filter(({ id }) => id.startsWith("comp_"))
              .map((r) => ({
                value: r.id,
                label: r.name,
              }))}
          />
        )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.User && (
          <FormikAsyncSelect
            className="flex-1"
            defaultOptions
            disabled={readonly}
            isMulti
            label={titlecase(resourcesLabel)}
            loadOptions={listCompanyUsers}
            loadOptionsMappers={{
              requestFilter: {
                limit: 10,
              },
              mapperFunction: (user) => ({
                value: user.id,
                label: user.name,
                resource: user,
              }),
            }}
            name={`${field}.resourceIds`}
            placeholder={`Type to select ${resourcesLabel}...`}
            onChange={(options: Option[]) => {
              const resourceIds = (condition.resources || []).map((r) => r.id);
              setFieldValue?.(`${field}.resources`, [
                ...(condition.resources || []),
                ...options
                  .filter((o) => !resourceIds.includes(o.value))
                  .map((o) => o.resource),
              ]);
            }}
            selectedOption={(condition.resources || [])
              .filter(Boolean)
              .filter(({ id }) => id.startsWith("user_"))
              .map((r) => ({
                value: r.id,
                label: r.name,
              }))}
          />
        )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.Plan && (
          <FormikAsyncSelect
            className="flex-1"
            defaultOptions
            disabled={readonly}
            isMulti
            label={titlecase(resourcesLabel)}
            loadOptions={listPlans()}
            loadOptionsMappers={{
              requestFilter: {
                limit: 10,
              },
              mapperFunction: (plan) => ({
                value: plan.id,
                label: plan.name,
                resource: plan,
              }),
            }}
            name={`${field}.resourceIds`}
            placeholder={`Type to select ${resourcesLabel}...`}
            onChange={(options: Option[]) => {
              const resourceIds = (condition.resources || []).map((r) => r.id);
              setFieldValue?.(`${field}.resources`, [
                ...(condition.resources || []),
                ...options
                  .filter((o) => !resourceIds.includes(o.value))
                  .map((o) => o.resource),
              ]);
            }}
            selectedOption={(condition.resources || [])
              .filter(Boolean)
              .filter(({ id }) => id.startsWith("plan_"))
              .map((r) => ({
                value: r.id,
                label: r.name,
              }))}
          />
        )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.BasePlan &&
          condition.operator !==
            CreateOrUpdateConditionRequestBodyOperatorEnum.IsEmpty &&
          condition.operator !==
            CreateOrUpdateConditionRequestBodyOperatorEnum.NotEmpty && (
            <FormikAsyncSelect
              className="flex-1"
              defaultOptions
              disabled={readonly}
              isMulti
              label={titlecase(resourcesLabel)}
              loadOptions={listPlans(PlanType.Plan)}
              loadOptionsMappers={{
                requestFilter: {
                  limit: 10,
                },
                mapperFunction: (plan) => ({
                  value: plan.id,
                  label: plan.name,
                  resource: plan,
                }),
              }}
              name={`${field}.resourceIds`}
              placeholder={`Type to select ${resourcesLabel}...`}
              onChange={(options: Option[]) => {
                const resourceIds = (condition.resources || []).map(
                  (r) => r.id,
                );
                setFieldValue?.(`${field}.resources`, [
                  ...(condition.resources || []),
                  ...options
                    .filter((o) => !resourceIds.includes(o.value))
                    .map((o) => o.resource),
                ]);
              }}
              selectedOption={(condition.resources || [])
                .filter(Boolean)
                .filter(({ id }) => id.startsWith("plan_"))
                .map((r) => ({
                  value: r.id,
                  label: r.name,
                }))}
            />
          )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.CrmProduct &&
          crmHubspotIntegrationFlag && (
            <FormikAsyncSelect
              className="flex-1"
              defaultOptions
              disabled={readonly}
              isMulti
              label={titlecase(resourcesLabel)}
              loadOptions={listCrmProducts}
              loadOptionsMappers={{
                requestFilter: {
                  limit: 10,
                },
                mapperFunction: (product) => ({
                  value: product.id,
                  label: product.name,
                  resource: product,
                }),
              }}
              name={`${field}.resourceIds`}
              placeholder={`Type to select ${titlecase(resourcesLabel)}...`}
              onChange={(options: Option[]) => {
                const resourceIds = (condition.resources || []).map(
                  (r) => r.id,
                );
                setFieldValue?.(`${field}.resources`, [
                  ...(condition.resources || []),
                  ...options
                    .filter((o) => !resourceIds.includes(o.value))
                    .map((o) => o.resource),
                ]);
              }}
              selectedOption={(condition.resources || [])
                .filter(Boolean)
                .filter(({ id }) => id.startsWith("crmp_"))
                .map((r) => ({
                  value: r.id,
                  label: r.name,
                }))}
            />
          )}

        {condition.conditionType ===
          CreateOrUpdateConditionRequestBodyConditionTypeEnum.BillingProduct &&
          stripeIntegrationFlag && (
            <FormikAsyncSelect
              className="flex-1"
              defaultOptions
              disabled={readonly}
              isMulti
              label={titlecase(resourcesLabel)}
              loadOptions={listBillingProducts}
              loadOptionsMappers={{
                requestFilter: {
                  limit: 10,
                },
                mapperFunction: (product: BillingProductResponseData) => ({
                  name: product.productId,
                  value: product.productId,
                  label: <BillingProductOptionLabel product={product} />,
                  resource: { ...product, id: product.productId },
                }),
              }}
              name={`${field}.resourceIds`}
              placeholder={`Type to select ${titlecase(resourcesLabel)}...`}
              onChange={(options: Option[]) => {
                const resourceIds = (condition.resources || []).map(
                  (r) => r.id,
                );
                setFieldValue?.(`${field}.resources`, [
                  ...(condition.resources || []),
                  ...options
                    .filter((o) => !resourceIds.includes(o.value))
                    .map((o) => o.resource),
                ]);
              }}
              selectedOption={(condition.resources || [])
                .filter(Boolean)
                .filter(({ id }) => id.startsWith("bilp_"))
                .map((r) => ({
                  value: r.id,
                  label: r.name,
                }))}
            />
          )}
      </div>

      <div className="flex pt-6 space-x-2 ml-2 items-start">
        {conditionsLength > 1 && (
          <Button
            className="!leading-none !h-[42px] !w-[42px] hover:!bg-red-500 hover:!text-white !text-blue-400 !text-3xl !p-0 !px-0 inline-flex items-center justify-center"
            color="white"
            type="button"
            disabled={readonly}
            onClick={() => onRemove && onRemove()}
            size="lg"
          >
            -
          </Button>
        )}
        {onAddAndCondition &&
          !onAddOrCondition &&
          conditionsLength < OR_CONDITION_MAX_LENGTH && (
            <Button
              className="!leading-none !h-[42px] !w-[42px] !text-3xl !py-0 !px-0 items-center justify-center inline-flex"
              color="blue"
              type="button"
              disabled={readonly}
              onClick={() => {
                onAddAndCondition();
              }}
              size="lg"
            >
              +
            </Button>
          )}
        {onAddAndCondition && onAddOrCondition && (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger
              asChild
              className={`rounded-[10px] flex items-center cursor-pointer ${
                readonly && "opacity-50 pointer-events-none"
              }`}
            >
              <div>
                <Button
                  className="!text-3xl !leading-none !p-0 inline-flex items-center justify-center h-[42px] w-[42px]"
                  color="blue"
                  type="button"
                  disabled={readonly}
                  size="lg"
                >
                  +
                </Button>
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                side="bottom"
                align="start"
                className="bg-white shadow-md rounded-lg w-full mt-2 flex flex-col min-w-[200px] cursor-pointer !z-40"
              >
                <DropdownMenu.Item
                  className={`flex px-6 py-2 text-sm justify-between hover:bg-gray-300 ${
                    disableOr && "opacity-50 pointer-events-none"
                  }`}
                  onClick={() => {
                    onAddOrCondition();
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="text-lg mr-4">
                      <span className="font-bold">OR</span> Condition
                    </div>
                  </div>
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  className={`flex px-6 py-2 text-sm justify-between hover:bg-gray-300 ${
                    disableAnd && "opacity-50 pointer-events-none"
                  }`}
                  onClick={() => {
                    onAddAndCondition();
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="text-lg mr-4">
                      <span className="font-bold">AND</span> Condition
                    </div>
                  </div>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}
      </div>
    </div>
  );
};
