import { useEditor } from "@craftjs/core";
import { type EmbedContextProps } from "@schematichq/schematic-components";
import ReactSelect, { type GroupBase, type Props } from "react-select";

interface SettingsSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  rawValue?: unknown;
}

export const Select = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  options = [],
  value,
  rawValue,
  onChange,
  ...rest
}: SettingsSelectProps<Option, IsMulti, Group>) => {
  const { settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={{
        control: (styles) => ({
          ...styles,
          height: "100%",
          minHeight: "auto",
          borderColor: "#E2E5E9",
        }),
        valueContainer: (styles, props) => ({
          ...styles,
          padding: props.isMulti ? "0.5rem" : "0 0.25rem",
        }),
        singleValue: (styles) => {
          if (!rawValue) {
            return styles;
          }

          const { fontFamily, fontWeight } =
            // @ts-expect-error: unknown option value
            settings.theme.typography[rawValue] || {};

          return {
            ...styles,
            fontFamily,
            fontWeight,
          };
        },
        multiValue: (styles) => ({
          ...styles,
          zIndex: 999999,
          padding: "0.125rem 0.5rem",
          borderRadius: "9999px",
        }),
        input: (styles) => ({
          ...styles,
          padding: 0,
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          display: "none",
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          padding: "0 0.25rem",
          color: "#667085",
        }),
        option: (styles, props) => {
          const { fontFamily, fontWeight } =
            // @ts-expect-error: unknown option value
            settings.theme.typography[props.data.value] || {};

          return {
            ...styles,
            fontFamily,
            fontWeight,
            padding: "0.25rem 0.75rem",
          };
        },
      }}
      {...rest}
    />
  );
};
