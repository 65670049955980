import { TableLoader } from "@components/loaders/TableLoader";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { usePermission } from "@hooks/usePermission";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import useTablePagination from "@hooks/useTablePagination";
import { CompanyDetailResponseData } from "@models/api";
import { ListCompaniesParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import * as api from "@modules/companies/queries";
import * as usersApi from "@modules/companyUsers/queries";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { ColumnDef } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { Logo } from "@ui/Logo";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";

import { CompaniesBlankState } from "../blank-states/CompaniesBlankState";
import { CompanyEditOverlay } from "../overlays";

export const CompaniesTable = () => {
  const navigate = useNavigateEnvironment();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListCompaniesParams>({});
  const companyEditAllowed = usePermission(ClerkUserPermission.companies_edit);

  const [overlay, setOverlay] = useState(false);
  const onOverlayClose = () => setOverlay(false);
  const onCreate = () => {
    setOverlay(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<CompanyDetailResponseData>[]>(
    () => [
      {
        id: "company",
        header: "Company",
        accessorKey: "company",
        cell: (cellInfo) => {
          const company = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <Logo src={company.logoUrl} alt={company.name} />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{company.name}</div>
                <div className="leading-none text-gray-400">{company.id}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "plan",
        header: "Plan",
        accessorKey: "plan",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original.plan;
          if (!plan) return <span className="text-gray-400">n/a</span>;

          return (
            <Pill
              text="normal"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`plans/${plan.id}`);
              }}
            >
              {plan.name}
            </Pill>
          );
        },
      },
      {
        id: "users",
        header: "Users",
        accessorKey: "users",
        cell: (cellInfo) => {
          const company = cellInfo.row.original;
          return (
            <>
              <div className="leading-none text-gray-400">
                {company.userCount}
              </div>
            </>
          );
        },
      },
      {
        id: "activity",
        header: () => (
          <span className="relative flex flex-row space-x-1 items-center">
            <span> Last seen </span>
            <span className="!tracking-[0] !leading-none !normal-case !font-display">
              <LabeledTooltip
                description="This is the date a track or identify event associated with a company was last received by Schematic."
                className="!bg-transparent"
                placement="bottom-left"
                size="lg"
              />
            </span>
          </span>
        ),
        accessorKey: "activity",
        cell: (cellInfo) => {
          const company = cellInfo.row.original;
          return (
            <>
              {company.lastSeenAt && (
                <div className="leading-none text-gray-400">
                  {moment(company.lastSeenAt).fromNow()}
                </div>
              )}
            </>
          );
        },
      },
    ],
    [navigate],
  );

  const getHeaderText = (count: number) => {
    return pluralize("Company", count, true);
  };

  const headerButtons: ButtonProps[] = useSchematicFlag("company.edit", {
    fallback: true,
  })
    ? [
        {
          children: <>Create</>,
          color: "blue",
          disabled: !companyEditAllowed,
          onClick: onCreate,
        },
      ]
    : [];

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<CompanyDetailResponseData, ListCompaniesParams>(
    ["companies"],
    api.listCompanies,
    api.countCompanies,
    filter,
    getHeaderText,
  );

  const secondaryHeaderText = useSecondaryTableHeader(
    "users",
    usersApi.countCompanyUsers,
  );

  const detailsLink = (row: CompanyDetailResponseData) => {
    return `companies/${row.id}`;
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noCompaniesCreated = countQuery?.data?.count === 0 && searchTerm === "";
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompaniesTable = () => {
    switch (true) {
      case noCompaniesCreated:
        return (
          <CompaniesBlankState
            onCreate={onCreate}
            disabled={!companyEditAllowed}
          />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              detailsLink={detailsLink}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <>
      <TableHeader
        headerText={headerText}
        searchPlaceholder={!noCompaniesCreated ? "Search companies" : ""}
        headerTabs={[
          {
            label: headerText,
            url: "../companies",
            active: true,
          },
          {
            label: secondaryHeaderText,
            url: "../users",
          },
        ]}
        onSearch={handleSearch}
        buttons={headerButtons}
      />
      {renderCompaniesTable()}
      {overlay && <CompanyEditOverlay onClose={onOverlayClose} />}
    </>
  );
};
