import { AddActivePlansOverlay } from "@modules/plans/components/overlays";
import { PlanConfigurationCard } from "@modules/plans/components/PlanConfigurationCard";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { useFormikContext } from "formik";
import { useState } from "react";

enum BilledPeriod {
  Monthly = "monthly",
  Yearly = "yearly",
}

export const LivePlansElevate = () => {
  const { setFieldValue, values, touched, errors } =
    useFormikContext<PlansConfigurationViewFormValues>();
  const [addActivePlansOverlay, setAddActivePlansOverlay] = useState(false);
  const [billedPeriod, setBilledPeriod] = useState(BilledPeriod.Monthly);

  const monthlyPlans = values.plans
    .filter((plan) => plan.monthlyPrice)
    .sort((a, b) => a.monthlyPrice!.price - b.monthlyPrice!.price);
  const yearlyPlans = values.plans
    .filter((plan) => plan.yearlyPrice)
    .sort((a, b) => a.yearlyPrice!.price - b.yearlyPrice!.price);

  return (
    <Elevate>
      <div className="flex justify-between items-center mb-4">
        <div className="text-xl flex items-center">
          <span className="h-3 w-3 bg-emerald-300 rounded-full inline-block mr-2"></span>
          Live plans
        </div>
        <div className="flex space-x-4">
          <LabeledTooltip
            description="Live Plans are visible in components and available for purchase."
            position="relative"
            placement="top-left"
            size="lg"
          />
          <div className="-space-x-4 flex border border-blue-100 rounded-3xl">
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Monthly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Monthly)}
            >
              {monthlyPlans.length} Billed Monthly
            </button>
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Yearly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Yearly)}
            >
              {yearlyPlans.length} Billed Yearly
            </button>
          </div>
        </div>
      </div>
      {((billedPeriod === BilledPeriod.Monthly && monthlyPlans.length === 0) ||
        (billedPeriod === BilledPeriod.Yearly && yearlyPlans.length === 0)) && (
        <Alert size="lg" style="light-gray" background="schematic">
          <div className="flex justify-center items-center">
            <Button
              color="blue"
              type="button"
              onClick={() => {
                setAddActivePlansOverlay(true);
              }}
            >
              Add plans
            </Button>
          </div>
        </Alert>
      )}

      {billedPeriod === BilledPeriod.Monthly && monthlyPlans.length > 0 && (
        <div className="flex mt-6 relative">
          <div className="relative overflow-x-hidden grow">
            <div className="flex grow space-x-4 overflow-x-scroll">
              {monthlyPlans.map((plan) => (
                <PlanConfigurationCard plan={plan} key={plan.id} />
              ))}
            </div>
          </div>
          <Button
            type="button"
            className="relative right-0 top-0 bottom-0 !text-3xl"
            color="white"
            onClick={() => {
              setAddActivePlansOverlay(true);
            }}
          >
            +
          </Button>
        </div>
      )}

      {billedPeriod === BilledPeriod.Yearly && yearlyPlans.length > 0 && (
        <div className="flex mt-6 relative">
          <div className="relative overflow-x-hidden grow">
            <div className="flex grow space-x-4 overflow-x-scroll">
              {yearlyPlans.map((plan) => (
                <PlanConfigurationCard plan={plan} key={plan.id} />
              ))}
            </div>
          </div>
          <Button
            type="button"
            className="relative right-0 top-0 bottom-0 !text-3xl"
            color="white"
            onClick={() => {
              setAddActivePlansOverlay(true);
            }}
          >
            +
          </Button>
        </div>
      )}

      {touched.planIds && errors.plans && errors.plans.length > 0 && (
        <Alert size="xs" style="red" className="mt-4">
          <div className="flex items-center justify-center space-x-2">
            <div className="text-base font-body ">
              <span className="font-semibold">Uh-oh!</span> All plans should
              have set-up default monthly or yearly price
            </div>
          </div>
        </Alert>
      )}

      {addActivePlansOverlay && (
        <AddActivePlansOverlay
          selectedPlans={values.plans}
          setActivePlans={async (plans) => {
            const planIds = plans.map((plan) => plan.id);

            await setFieldValue("plans", plans);
            await setFieldValue("planIds", planIds);

            if (
              values.defaultPlanId &&
              !planIds.includes(values.defaultPlanId)
            ) {
              await setFieldValue("defaultPlan", undefined);
              await setFieldValue("defaultPlanId", undefined);
            }
          }}
          onClose={() => {
            setAddActivePlansOverlay(false);
          }}
        />
      )}
    </Elevate>
  );
};
