import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import { type FeatureDetailResponseData } from "@models/api";
import {
  useEmbed,
  Element,
  MeteredFeatures as EmbedMeteredFeatures,
  type EmbedContextProps,
  type MeteredFeaturesProps,
} from "@schematichq/schematic-components";
import { titlecase } from "@utils/strings";
import { useMemo, useState } from "react";
import {
  components,
  type MultiValueGenericProps,
  type MultiValueProps,
  type OnChangeValue,
  type GroupBase,
  type Props,
} from "react-select";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import * as Settings from "../../controls/Sidebar/Settings";
import { Checkbox, Select } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );
  return slicedArray;
}

interface FeatureOption {
  value: string;
  label: string;
}

export const MeteredFeatures: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as MeteredFeaturesProps,
  }));

  const { settings } = useEmbed();

  if (!props.visibleFeatures.length) {
    return (
      <Element
        ref={(ref) => connect(drag(ref!))}
        className="relative z-[2] cursor-pointer flex justify-center"
      >
        <h1
          className="text-center leading-tight"
          style={{
            fontFamily: settings.theme.typography.heading1.fontFamily,
            fontSize: settings.theme.typography.heading1.fontSize,
            fontWeight: settings.theme.typography.heading1.fontWeight,
            color: settings.theme.typography.heading1.color,
          }}
        >
          No features selected
        </h1>
      </Element>
    );
  }

  return (
    <EmbedMeteredFeatures
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const FeatureSelect =
  SortableContainer<Props<FeatureOption, true, GroupBase<FeatureOption>>>(
    Select,
  );

const FeatureMultiValue = SortableElement<
  MultiValueProps<FeatureOption, true, GroupBase<FeatureOption>>
>((props: MultiValueProps<FeatureOption>) => {
  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const FeatureMultiValueLabel = SortableHandle<
  MultiValueGenericProps<FeatureOption, true, GroupBase<FeatureOption>>
>((props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />);

const MeteredFeaturesSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as MeteredFeaturesProps,
  }));

  const { data } = useEmbed();

  const [selected, setSelected] = useState<readonly FeatureOption[]>(
    (data.featureUsage?.features || [])
      .filter(
        ({ feature }) =>
          feature &&
          feature.id &&
          (feature.featureType === "event" ||
            feature.featureType === "trait") &&
          nodeProps.visibleFeatures.includes(feature.id),
      )
      .map((feat) => {
        const feature = feat.feature as FeatureDetailResponseData;
        return { value: feature.id, label: feature.name };
      }),
  );

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  const featureOptions = useMemo(() => {
    return data.featureUsage?.features.reduce(
      (acc: { value: string; label: string }[], { feature }) => {
        if (
          !feature ||
          (feature.featureType !== "event" && feature.featureType !== "trait")
        ) {
          return acc;
        }

        return [
          ...acc,
          {
            value: feature.id,
            label: feature.name,
          },
        ];
      },
      [],
    );
  }, [data.featureUsage?.features]);

  return (
    <Settings.Root title="🎛️ Metered Features" category="Entitlements">
      <Settings.Section>
        <div className="pb-2 space-y-1">
          <div>Features Displayed</div>
          <div className="text-[0.8125rem] text-[#939393]">
            Show in order below
          </div>
        </div>

        <Settings.Input>
          <FeatureSelect
            className="w-full min-h-12"
            useDragHandle
            axis="xy"
            onSortEnd={({ oldIndex, newIndex }) => {
              const newValue = arrayMove(selected, oldIndex, newIndex);
              setSelected(newValue);
            }}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            isMulti
            options={featureOptions}
            value={selected}
            onChange={(selectedOptions: OnChangeValue<FeatureOption, true>) => {
              setSelected(selectedOptions);
              setProp((props: MeteredFeaturesProps) => {
                props.visibleFeatures = selectedOptions.map(
                  (option) => option.value,
                );
              });
            }}
            components={{
              MultiValue: FeatureMultiValue,
              MultiValueLabel: FeatureMultiValueLabel,
            }}
            closeMenuOnSelect={false}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.isVisible}
          onVibibilityChange={() => {
            setProp((props: MeteredFeaturesProps) => {
              const updated = !props.isVisible;
              props.isVisible = updated;
            });
          }}
        >
          Feature Meter
        </Settings.Header>

        <Settings.Input label="Title style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show description">
          <Checkbox
            checked={nodeProps.description.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.description.isVisible;
                props.description.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Description text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.description.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.description.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.description.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show icon">
          <Checkbox
            checked={nodeProps.icon.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.icon.isVisible;
                props.icon.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show usage">
          <Checkbox
            checked={nodeProps.usage.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.usage.isVisible;
                props.usage.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Usage text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.usage.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.usage.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.usage.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show limit">
          <Checkbox
            checked={nodeProps.allocation.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.allocation.isVisible;
                props.allocation.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Limit text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.allocation.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.allocation.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.allocation.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

MeteredFeatures.craft = {
  displayName: "Metered Features",
  props: {
    isVisible: true,
    header: {
      fontStyle: "heading2",
    },
    description: {
      isVisible: true,
      fontStyle: "text",
    },
    icon: {
      isVisible: true,
    },
    usage: {
      isVisible: true,
      fontStyle: "heading5",
    },
    allocation: {
      isVisible: true,
      fontStyle: "heading6",
    },
    visibleFeatures: [],
  } satisfies MeteredFeaturesProps,
  related: {
    settings: MeteredFeaturesSettings,
  },
};
