import { CompanyDetailResponseData } from "@models/api";
import { EntityKeyDetailResponseData } from "@models/api";
import { UserDetailResponseData } from "@models/api";
import {
  DetailData,
  formatEntityTraitValue,
  formatKeyValue,
} from "@utils/helpers";
import cx from "classnames";
import moment from "moment";
import { useState } from "react";
import { Alert } from "../Alert";
import { ClipCopy } from "../ClipCopy";
import { LabelDataGrid, LabelData } from "../LabelData";
import { Overlay, OverlayModal, OverlayHeader } from "../Overlay";
import { Pill } from "../Pill";

interface MetadataProps {
  data: CompanyDetailResponseData | UserDetailResponseData;
  title?: string;
  className?: string;
}

export const Metadata = ({ data, title, className }: MetadataProps) => {
  const [overlay, setOverlay] = useState(false);
  const handleClose = () => setOverlay(false);
  const handleOpen = () => setOverlay(true);

  const updatedAt = data.updatedAt ? (
    <div title={moment(data.updatedAt).format("LLLL")}>
      {moment(data.updatedAt).format("LLLL")}
    </div>
  ) : (
    <div title={moment(data.createdAt).format("LLLL")}>
      {moment(data.createdAt).format("LLLL")}
    </div>
  );

  const createdAt = data.createdAt && (
    <div title={moment(data.createdAt).format("LLLL")}>
      {moment(data.createdAt).format("LLLL")}
    </div>
  );

  const metaDetails: DetailData[] = [
    {
      label: "Name",
      children: data.name,
    },
    {
      label: "Schematic ID",
      children: <ClipCopy data={data.id} />,
    },
    {
      label: "Updated at",
      children: updatedAt,
    },
    {
      label: "Created at",
      children: createdAt,
    },
  ];

  const metaKeys: DetailData[] = [
    ...(data.keys || []).map((key: EntityKeyDetailResponseData) =>
      formatKeyValue(key, true),
    ),
  ];

  const metaTraits = [
    ...((data.entityTraits || [])
      .filter((trait) => trait.definition)
      .map((trait) => {
        const { definition } = trait;
        if (!definition) return null;

        return {
          name: definition.hierarchy.join("."),
          type: definition.traitType,
          value: formatEntityTraitValue(trait),
        };
      }) || []),
  ];

  return (
    <div className={cx("", className)}>
      <div className="flex justify-between items-center mb-8">
        <div className="text-3xl">{title ? title : "Overview"}</div>
        <div
          className="text-blue-500 font-medium cursor-pointer hover:underline"
          onClick={handleOpen}
        >
          All meta
        </div>
      </div>
      <Alert style="light-gray" size="md">
        <LabelDataGrid columns="4">
          {metaDetails.map((d: DetailData, index: number) => {
            return (
              <LabelData label={d.label} key={index.toString()}>
                {d.children}
              </LabelData>
            );
          })}
        </LabelDataGrid>
      </Alert>
      {overlay && (
        <Overlay onClose={handleClose}>
          <OverlayModal size="lg" position="right">
            <OverlayHeader
              title={`${data.name} Metadata`}
              onClose={handleClose}
            />
            <div className="px-12 mt-12 space-y-12">
              <div>
                <div className="space-y-6">
                  {metaDetails.map((d: DetailData, index: number) => {
                    return (
                      <LabelData
                        variant="row"
                        label={d.label}
                        key={index.toString()}
                      >
                        {d.children}
                      </LabelData>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="text-lg font-medium mb-8">Keys</div>
                <div className="space-y-6">
                  {metaKeys.map((d: DetailData, index: number) => {
                    return (
                      <LabelData
                        variant="row"
                        label={
                          <>
                            <Pill color="gray" type="tag" text="code">
                              {d.label}
                            </Pill>
                          </>
                        }
                        key={index.toString()}
                      >
                        {d.children}
                      </LabelData>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="text-lg font-medium mb-8">Traits</div>
                <div className="space-y-6">
                  {metaTraits.map((d: any, index: number) => {
                    return (
                      <LabelData
                        variant="row"
                        className="relative hover:text-blue-400 group hover:cursor-help"
                        label={
                          <>
                            <Pill color="gray" type="tag" text="code">
                              {d.name}
                            </Pill>
                          </>
                        }
                        key={index.toString()}
                      >
                        {d.value ? d.value : "-"}
                        <div className="group-hover:opacity-100 group-hover:visible opacity-0 invisible min-w-[180px] bg-white shadow-lg absolute !left-0 bottom-full rounded-md flex flex-col space-y-2 z-20 p-5 mb-2">
                          <div className="flex w-full justify-between space-x-8">
                            <div className="text-black">Type</div>
                            <div className="lowercase">{d.type}</div>
                          </div>
                        </div>
                      </LabelData>
                    );
                  })}
                </div>
              </div>
            </div>
          </OverlayModal>
        </Overlay>
      )}
    </div>
  );
};
