import * as PrimitiveTab from "@radix-ui/react-tabs";
import cx from "classnames";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";

export type TabTypes = {
  content: ReactNode;
  key: string;
  name: string;
  url?: string;
};

export interface TabsProps {
  ariaLabel?: string;
  className?: string;
  data: TabTypes[];
  selected?: string;
  style?: "horizontal" | "vertical" | "page" | "compact";
  value?: string;
  onValueChange?: (value: string) => void;
}

export const Tabs = ({
  ariaLabel = "Choose Sub Page",
  className,
  data,
  selected,
  style = "horizontal",
  value,
  onValueChange,
}: TabsProps) => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };

  const tabsStylesMap = {
    horizontal: "flex flex-row space-x-6 flex-1",
    vertical: "flex flex-col space-y-6 flex-1 w-auto max-w-[100px]",
    page: "",
    compact: "flex flex-row space-x-6 flex-1",
  };

  const styleMap = {
    horizontal: "tabs-horizontal",
    vertical: "tabs-vertical",
    page: "",
    compact: "!mt-0",
  };

  const wrapperStyleMap = {
    horizontal: "",
    vertical: "",
    page: "",
    compact: "!bg-white w-[calc(100%_+4rem)] !mt-0 !pt-0 !border-gray-400/20",
  };

  const urlMap = data.reduce((acc, curr) => {
    if (curr.url) {
      acc[curr.key] = `/${environmentId}${curr.url}`;
    }
    return acc;
  }, {} as Record<string, string>);

  const handleValueChange = (value: string) => {
    if (urlMap[value]) {
      window.history.pushState({}, "", urlMap[value]);
    }

    onValueChange?.(value);
  };

  const styles = cx("z-40 relative", styleMap[style], className);

  return (
    <PrimitiveTab.Root
      className={styles}
      onValueChange={handleValueChange}
      {...(typeof value === "undefined"
        ? { defaultValue: selected || data[0]?.key }
        : { value })}
    >
      <div className={cx("tabs-wrapper", wrapperStyleMap[style])}>
        <div className="tw-wrapper">
          <PrimitiveTab.List
            className={tabsStylesMap[style]}
            aria-label={ariaLabel}
          >
            {data.map((t, idx) => (
              <PrimitiveTab.Trigger
                className="tabs-item capitalize"
                value={t.key}
                key={idx}
              >
                <div className="tabs-item-text" data-text={t.name}>
                  <div className="relative">{t.name}</div>
                </div>
              </PrimitiveTab.Trigger>
            ))}
          </PrimitiveTab.List>
        </div>
      </div>

      <div className="tabs-content-wrapper !p-0">
        <div className="tw-wrapper">
          {data.map((t, idx) => (
            <PrimitiveTab.Content
              className="tabs-content"
              value={t.key}
              key={idx}
            >
              {t.content}
            </PrimitiveTab.Content>
          ))}
        </div>
      </div>
    </PrimitiveTab.Root>
  );
};
