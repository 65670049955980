import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listFeatures } from "@data/features";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import {
  CompanyResponseData,
  CreateCompanyOverrideRequestBody,
  EntityTraitDefinitionResponseData,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
} from "@models/api";
import { CompanyOverride, EntitlementValueType } from "@models/entitlement";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriods,
  Feature,
  FeatureType,
} from "@models/feature";
import { listCompanies } from "@modules/companies/queries";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { listEntityTraitDefinitions } from "@modules/settings/queries/entityTraits";
import { Separator as DropdownSeparator } from "@radix-ui/react-dropdown-menu";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { DynamicTitle } from "@ui/DynamicTitle";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import {
  Overlay,
  OverlayFooter,
  OverlayHeaderClose,
  OverlayModal,
} from "@ui/Overlay";

import { EntityTraitLabel } from "@ui/RuleBlock/EntityTraitLabel";
import { Separator } from "@ui/Separator";
import { Switch } from "@ui/Switch";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  createCompanyOverride,
  updateCompanyOverride,
} from "../../queries/companyOverrides";

type FormValues = CreateCompanyOverrideRequestBody & {
  company?: CompanyResponseData;
  feature?: Feature;
  trait?: EntityTraitDefinitionResponseData;
};

export interface CompanyOverrideOverlayProps {
  company?: CompanyResponseData;
  feature?: Feature;
  companyOverride?: CompanyOverride;
  onClose: () => void;
}

export const CompanyOverrideOverlay = ({
  company,
  companyOverride,
  feature,
  onClose,
}: CompanyOverrideOverlayProps) => {
  feature ||= companyOverride?.feature;
  company ||= companyOverride?.company;

  const stripeIntegrationFlag = useSchematicFlag("stripe-integration-flag", {
    fallback: true,
  });

  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const defaultValueType =
    feature?.featureType === FeatureType.Boolean
      ? EntitlementValueType.Boolean
      : EntitlementValueType.Numeric;

  const initialValues: FormValues = {
    company: companyOverride?.company ?? company,
    companyId: companyOverride?.companyId ?? company?.id ?? "",
    feature: companyOverride?.feature ?? feature,
    featureId: companyOverride?.featureId ?? feature?.id ?? "",
    metricPeriod: companyOverride?.metricPeriod ?? undefined,
    trait: companyOverride?.valueTrait,
    valueBool: companyOverride?.valueBool ?? true,
    valueNumeric: companyOverride?.valueNumeric ?? 0,
    valueTraitId: companyOverride?.valueTraitId,
    valueType: companyOverride?.valueType ?? defaultValueType,
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required("Required"),
    featureId: Yup.string().required("Required"),
    metricPeriod: Yup.string().when(["feature", "valueType"], {
      is: (feature: Feature, valueType: EntitlementValueType) =>
        feature?.featureType == FeatureType.Event &&
        valueType !== EntitlementValueType.Unlimited,
      then: () => Yup.string().required("Required"),
    }),
    valueNumeric: Yup.string().when("valueType", {
      is: (valueType: EntitlementValueType) =>
        valueType === EntitlementValueType.Numeric,
      then: () => Yup.string().required("Required"),
    }),
    valueTraitId: Yup.string().when("valueType", {
      is: (valueType: EntitlementValueType) =>
        valueType === EntitlementValueType.Trait,
      then: () => Yup.string().required("Required"),
    }),
    valueType: Yup.string().required("Required"),
  });

  const metricPeriodOptions = ConditionMetricPeriods.filter((period) => {
    if (period === CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Billing) {
      return stripeIntegrationFlag;
    }
    return true;
  }).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const onSubmit = async (
    { feature, company, trait, ...rest }: FormValues,
    helpers: FormikHelpers<CreateCompanyOverrideRequestBody>,
  ) => {
    setLoading(true);

    try {
      const saveFn = companyOverride?.id
        ? (values: CreateCompanyOverrideRequestBody) =>
            updateCompanyOverride(companyOverride.id, values)
        : createCompanyOverride;

      await saveFn(rest);

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md">
        <OverlayHeaderClose onClose={onClose} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, setFieldValue, errors, isValid }) => (
            <Form className="flex flex-col p-12">
              <div className="flex flex-col flex-1 h-full">
                <div className="flex-1">
                  <FormHeader
                    label="Company override"
                    title={
                      <DynamicTitle
                        fromLabel={values.feature?.name ?? "Feature"}
                        from={values.feature?.name}
                        toLabel={values.company?.name ?? "Company"}
                        to={values.company?.name}
                      />
                    }
                    compact
                  />

                  <FormColumn>
                    <FormikAsyncSelect
                      label="Company"
                      name="company"
                      defaultOptions
                      disabled={!!company}
                      loadOptions={listCompanies}
                      loadOptionsMappers={{
                        requestFilter: feature && {
                          withoutFeatureOverrideFor: feature.id,
                        },
                      }}
                      selectedOption={
                        values.company && {
                          value: values.company,
                          label: values.company?.name || values.companyId,
                        }
                      }
                      onChange={async (option: {
                        value: CompanyResponseData;
                        label: string;
                      }) => {
                        await setFieldValue("companyId", option?.value.id);
                      }}
                    />

                    <Separator />

                    <FormikAsyncSelect
                      label="Feature"
                      name="feature"
                      defaultOptions
                      disabled={!!feature}
                      loadOptions={listFeatures}
                      loadOptionsMappers={{
                        requestFilter: company && {
                          withoutCompanyOverrideFor: company.id,
                        },
                      }}
                      selectedOption={
                        values.feature && {
                          value: values.feature,
                          label: values.feature?.name || values.featureId,
                        }
                      }
                      onChange={async (option: {
                        value: Feature;
                        label: string;
                      }) => {
                        await setFieldValue("featureId", option?.value.id);
                        if (option?.value.featureType === FeatureType.Boolean) {
                          await Promise.all([
                            setFieldValue(
                              "valueType",
                              EntitlementValueType.Boolean,
                            ),
                            setFieldValue("metricPeriod", undefined),
                            setFieldValue("valueBool", true),
                            setFieldValue("valueNumeric", undefined),
                            setFieldValue(
                              "valueType",
                              EntitlementValueType.Boolean,
                            ),
                          ]);
                        } else {
                          await Promise.all([
                            setFieldValue("valueType", defaultValueType),
                            setFieldValue("valueBool", undefined),
                            setFieldValue("valueNumeric", 0),

                            // If value type was set to boolean, set it to numeric, since boolean value type is invalid for a non-boolean feature
                            values.valueType === EntitlementValueType.Boolean &&
                              setFieldValue(
                                "valueType",
                                EntitlementValueType.Numeric,
                              ),
                          ]);
                        }
                      }}
                    />

                    {!isValid && (
                      <Alert size="xs" style="yellow">
                        {JSON.stringify(errors)}
                      </Alert>
                    )}

                    {values.feature && values.company && (
                      <>
                        <DropdownSeparator className="my-6" />

                        <FormRow className="justify-between">
                          <div>Define entitlements limits</div>
                          <FeatureTypeCell
                            featureType={values.feature.featureType}
                          />
                        </FormRow>

                        {values.feature.featureType === FeatureType.Boolean && (
                          <FormRow>
                            <Switch
                              name="valueBool"
                              label={values.valueBool ? "On" : "Off"}
                              labelPlacement="right"
                              checked={!!values.valueBool}
                              onCheckedChange={async (checked) => {
                                await setFieldValue("valueBool", checked);
                              }}
                            />
                          </FormRow>
                        )}

                        {values.feature.featureType === FeatureType.Trait && (
                          <FormRow>
                            <FormikControl
                              control="select"
                              label="Type"
                              name="valueType"
                              options={[
                                {
                                  label: "Numerical",
                                  value: EntitlementValueType.Numeric,
                                },
                                {
                                  label: "No limit",
                                  value: EntitlementValueType.Unlimited,
                                },
                                {
                                  label: "Trait",
                                  value: EntitlementValueType.Trait,
                                },
                              ]}
                            />
                            {values.valueType ===
                              EntitlementValueType.Numeric && (
                              <FormikControl
                                control="input"
                                label="Value"
                                name="valueNumeric"
                                type="number"
                              />
                            )}
                            {values.valueType ===
                              EntitlementValueType.Trait && (
                              <FormikAsyncSelect
                                label="Trait"
                                name="trait"
                                defaultOptions
                                loadOptions={listEntityTraitDefinitions}
                                loadOptionsMappers={{
                                  requestFilter: {
                                    entityType: EntityType.Company,
                                    traitType: EntityTraitType.Number,
                                  },
                                  mapperFunction: (trait) => ({
                                    value: trait,
                                    label: (
                                      <EntityTraitLabel entityTrait={trait} />
                                    ),
                                  }),
                                  resultsFilter: (trait) => !!trait.id,
                                }}
                                selectedOption={
                                  values.trait && {
                                    value: values.trait.id,
                                    label: (
                                      <EntityTraitLabel
                                        entityTrait={values.trait}
                                      />
                                    ),
                                  }
                                }
                                onChange={async (option) => {
                                  await setFieldValue(
                                    "valueTraitId",
                                    option?.value.id,
                                  );
                                }}
                              />
                            )}
                          </FormRow>
                        )}

                        {values.feature.featureType === FeatureType.Event && (
                          <FormRow>
                            <FormikControl
                              control="select"
                              label="Type"
                              name="valueType"
                              options={[
                                {
                                  label: "Numerical",
                                  value: EntitlementValueType.Numeric,
                                },
                                {
                                  label: "No limit",
                                  value: EntitlementValueType.Unlimited,
                                },
                                {
                                  label: "Trait",
                                  value: EntitlementValueType.Trait,
                                },
                              ]}
                            />
                            {values.valueType ===
                              EntitlementValueType.Numeric && (
                              <FormikControl
                                control="input"
                                label="Value"
                                name="valueNumeric"
                                type="number"
                              />
                            )}
                            {values.valueType ===
                              EntitlementValueType.Trait && (
                              <FormikAsyncSelect
                                label="Trait"
                                name="trait"
                                defaultOptions
                                loadOptions={listEntityTraitDefinitions}
                                loadOptionsMappers={{
                                  requestFilter: {
                                    entityType: EntityType.Company,
                                    traitType: EntityTraitType.Number,
                                  },
                                  mapperFunction: (trait) => ({
                                    value: trait,
                                    label: (
                                      <EntityTraitLabel entityTrait={trait} />
                                    ),
                                  }),
                                  resultsFilter: (trait) => !!trait.id,
                                }}
                                selectedOption={
                                  values.trait && {
                                    value: values.trait,
                                    label: values.trait.displayName,
                                  }
                                }
                                onChange={async (option) => {
                                  await setFieldValue(
                                    "valueTraitId",
                                    option?.value.id,
                                  );
                                }}
                              />
                            )}

                            {values.valueType !==
                              EntitlementValueType.Unlimited && (
                              <FormikControl
                                control="select"
                                name="metricPeriod"
                                label="Period"
                                options={metricPeriodOptions}
                              />
                            )}
                          </FormRow>
                        )}
                      </>
                    )}

                    {apiError && (
                      <div className="px-2">
                        <Alert size="xs" style="red">
                          <div className="flex items-center justify-center space-x-2">
                            <div className="text-base font-body ">
                              <span className="font-semibold">Uh-oh!</span>{" "}
                              {apiError}
                            </div>
                          </div>
                        </Alert>
                      </div>
                    )}
                  </FormColumn>
                </div>
                {values.featureId && values.companyId && (
                  <>
                    <DropdownSeparator className="my-6" />
                    <OverlayFooter>
                      <button
                        className="button button-sm button-blue"
                        type="submit"
                      >
                        Save changes
                      </button>
                    </OverlayFooter>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
