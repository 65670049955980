import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { errorMessage } from "@data/index";
import { listIntegrations } from "@data/integrations";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import {
  PlanGroupPlanDetailResponseData,
  UpdatePlanGroupRequestBody,
} from "@models/api";
import { PlanType } from "@models/plan";
import { countPlans, listPlans } from "@modules/plans";
import { ConnectStripeBreakdown } from "@modules/plans/components/ConnectStripeBreakdown";
import { LivePlansElevate } from "@modules/plans/components/LivePlansElevate";
import { PlanChangeRules } from "@modules/plans/components/PlanChangeRules";
import { PlanRoutePaths } from "@modules/plans/consts";
import {
  createPlansConfiguration,
  getPlansConfiguration,
  updatePlansConfiguration,
} from "@modules/plans/queries/planConfiguration";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { PlanLabel } from "@ui/PlanLabel";
import { TableHeader } from "@ui/TableHeader";
import { Toast } from "@ui/Toast";
import { ViewWrapper } from "@ui/ViewWrapper";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export type PlansConfigurationViewFormValues = {
  id?: string;
  plans: PlanGroupPlanDetailResponseData[];
  planIds: string[];
  defaultPlan?: PlanGroupPlanDetailResponseData;
  defaultPlanId?: string;
};

type DefaultPlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

const validationSchema = Yup.object().shape({
  plans: Yup.array().of(
    Yup.object()
      .shape({
        yearlyPrice: Yup.object(),
        monthlyPrice: Yup.object(),
      })
      .test(
        "monthlyPrice or yearlyPrice",
        "Either monthly or yearly plan price is required",
        ({ monthlyPrice, yearlyPrice }) => !!monthlyPrice || !!yearlyPrice,
      ),
  ),
  planIds: Yup.array().of(Yup.string()).min(0).max(100),
  defaultPlanId: Yup.string(),
});

export const PlansConfigurationView = () => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const [toastOpen, setToastOpen] = useState(false);

  const planConfigurationFlag = useSchematicFlag("plan-configuration", {
    fallback: true,
  });

  const plansHeaderText = useSecondaryTableHeader(
    "plans",
    countPlans(PlanType.Plan),
  );
  const addonsHeaderText = useSecondaryTableHeader(
    "add ons",
    countPlans(PlanType.AddOn),
  );

  const onSubmit = async (
    { id, plans, defaultPlan }: PlansConfigurationViewFormValues,
    helpers: FormikHelpers<PlansConfigurationViewFormValues>,
  ) => {
    setLoading(true);

    const req = {
      planIds: plans.map((p) => p.id),
      defaultPlanId: defaultPlan?.id,
    };

    const saveFn = id
      ? (values: UpdatePlanGroupRequestBody) =>
          updatePlansConfiguration(id, values)
      : createPlansConfiguration;

    try {
      await saveFn(req);

      await queryClient.invalidateQueries();
      setApiError(undefined);
      helpers.setSubmitting(false);

      setToastOpen(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  const { data, isLoading } = useContextQuery({
    queryKey: ["plans", "configuration"],
    queryFn: async () => {
      try {
        return await getPlansConfiguration();
      } catch (error: any) {
        if (error.responseCode === 404) {
          return {
            id: undefined,
            defaultPlan: undefined,
            defaultPlanId: undefined,
            plans: [],
            planIds: [],
          };
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const { data: integrationsData, isLoading: isIntegrationsLoading } =
    useContextQuery({
      queryKey: ["integrations", "stripe"],
      queryFn: async () => {
        try {
          return await listIntegrations({ type: "stripe" });
        } catch (error: any) {
          // TODO: Fiz integrations returning 500
          if (error.responseCode <= 500) {
            return [];
          }

          throw error;
        }
      },
      retry: (failureCount, error: any) => {
        // TODO: Fiz integrations returning 500
        if (error.responseCode <= 500) {
          return false;
        }

        return failureCount < 3;
      },
    });

  const stripeConnected = integrationsData?.[0]?.state;

  if (isLoading || isIntegrationsLoading) {
    return <SinglePageLoader />;
  }

  const initialValues: PlansConfigurationViewFormValues = {
    id: data?.id,
    plans: data?.plans || [],
    planIds: (data?.plans || []).map((plan) => plan.id),
    defaultPlan: data?.defaultPlan,
    defaultPlanId: data?.defaultPlan?.id,
  };

  if (!planConfigurationFlag) {
    return;
  }

  return (
    <ViewWrapper>
      <Toast
        title="Plan configuration changes saved."
        open={toastOpen}
        setOpen={setToastOpen}
        position="center"
        duration={2000}
      />
      <div className="pb-16">
        {/*TODO: Maybe change to some slimmer loader*/}
        {loading && <SchematicOverlayLoader />}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          enableReinitialize
        >
          {({
            touched,
            values,
            setFieldValue,
            dirty,
            resetForm,
            submitForm,
          }) => {
            return (
              <>
                <TableHeader
                  className="-mt-8"
                  headerTabs={[
                    {
                      label: plansHeaderText,
                      url: `/${environmentId}/${PlanRoutePaths.Plans}`,
                    },
                    {
                      label: addonsHeaderText,
                      url: `/${environmentId}/${PlanRoutePaths.AddOns}`,
                    },
                    {
                      active: true,
                      label: "Configuration",
                      url: `/${environmentId}/${PlanRoutePaths.Configuration}`,
                    },
                  ]}
                  buttons={[
                    {
                      children: <>Cancel</>,
                      color: "white",
                      disabled: !dirty,
                      onClick: () => {
                        resetForm();
                      },
                    },
                    {
                      children: <>Save changes</>,
                      color: "blue",
                      disabled: !dirty,
                      onClick: () => {
                        submitForm();
                      },
                    },
                  ]}
                />

                <Form>
                  {apiError && (
                    <div className="px-2 mt-4">
                      <Alert size="xs" style="red">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="text-base font-body ">
                            <span className="font-semibold">Uh-oh!</span>{" "}
                            {apiError}
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}

                  {!stripeConnected && <ConnectStripeBreakdown page="config" />}
                  <div className="flex flex-col space-y-8">
                    {stripeConnected && <LivePlansElevate />}

                    <Elevate>
                      {touched.defaultPlanId && (
                        <div className="w-full mb-4">
                          <Alert size="sm" style="yellow" className="w-full">
                            If you change the default plan, that plan's current
                            audience will be overwritten.
                          </Alert>
                        </div>
                      )}
                      <div className="w-full flex justify-between items-center">
                        <div className="text-xl">Default plan</div>
                        <div className="flex items-center space-x-8">
                          <div className="text-sm flex justify-center items-center whitespace-nowrap">
                            Companies without another base plan will be assigned
                            to this plan.
                            <LabeledTooltip
                              description="Plans that are linked to billing products cannot be selected as the default plan"
                              position="relative"
                              placement="top-left"
                              size="lg"
                            />
                          </div>
                          <FormikAsyncSelect
                            className="max-w-xs"
                            name="defaultPlanId"
                            placeholder="No default plan selected"
                            defaultOptions
                            loadOptions={listPlans(PlanType.Plan)}
                            loadOptionsMappers={{
                              mapperFunction: (plan) => ({
                                entity: plan,
                                label: <PlanLabel plan={plan} font="normal" />,
                                value: plan.id,
                              }),
                              requestFilter: {
                                withoutProductId: true,
                              },
                            }}
                            selectedOption={
                              values?.defaultPlan && {
                                value: values.defaultPlan.id,
                                label: (
                                  <PlanLabel
                                    font="normal"
                                    plan={values.defaultPlan}
                                  />
                                ),
                                entity: values.defaultPlan,
                              }
                            }
                            onChange={async (option: DefaultPlanOption) => {
                              await setFieldValue(
                                "defaultPlan",
                                option?.entity,
                              );
                              await setFieldValue(
                                "defaultPlanId",
                                option?.value,
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Elevate>

                    {stripeConnected && <PlanChangeRules />}
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </ViewWrapper>
  );
};
