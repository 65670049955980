import { useNode, type UserComponent } from "@craftjs/core";
import { Column as EmbedColumn } from "@schematichq/schematic-components";

/* const Indicators = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none">
      <div className="absolute z-[1] top-1/2 -left-[2px] -translate-y-1/2 w-[5px] h-4 bg-white border-2 border-black" />
      <div className="absolute z-[1] top-1/2 -right-[2px] -translate-y-1/2 w-[5px] h-4 bg-white border-2 border-black" />
    </div>
  );
}; */

export interface ColumnProps {
  id: string;
  children?: React.ReactNode;
}

export const Column: UserComponent<ColumnProps> = ({ children }) => {
  const {
    connectors: { connect },
  } = useNode();

  return (
    <EmbedColumn ref={(ref) => connect(ref!)} className="layout p-2 min-h-32">
      {children}
    </EmbedColumn>
  );
};

Column.craft = {
  displayName: "Column",
  rules: {
    canDrag: () => false,
    canDrop: () => false,
  },
};
