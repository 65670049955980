import { useContextQuery } from "@hooks/useContextQuery";
import {
  BillingCustomerSubscription,
  BillingCustomerWithSubscriptionsResponseData,
  ListCustomersRequest,
} from "@models/api";
import {
  countCustomers,
  listCustomers,
} from "@modules/integrations/queries/customers";
import { AuditLogOverlay } from "@modules/settings/components/overlays/AuditLogOverlay";
import { ColumnDef } from "@tanstack/react-table";
import { ClipCopy } from "@ui/ClipCopy";
import { InfiniteTable } from "@ui/InfiniteTable";
import { SettingsBox } from "@ui/SettingsBox";
import { TableHeader } from "@ui/TableHeader";
import { formatCurrency } from "@utils/strings";
import React, { useEffect, useMemo, useState } from "react";

export const BillingCustomersTable = () => {
  const [logOverlay, setLogOverlay] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListCustomersRequest>({});
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const getImportedCustomersCount = useContextQuery({
    queryKey: ["getImportedCustomersCount"],
    queryFn: () => countCustomers(filter),
  });

  useEffect(() => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      q: searchTerm === "" ? undefined : searchTerm,
    }));
  }, [searchTerm]);

  const columns = useMemo<
    ColumnDef<BillingCustomerWithSubscriptionsResponseData>[]
  >(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 170,
        cell: (cellInfo) => {
          const customer = cellInfo.row.original;
          return (
            <span className="text-gray-400 text-sm">
              {customer.name ? customer.name : "N/A"}
            </span>
          );
        },
      },
      {
        id: "subscription",
        header: "Subscription",
        accessorKey: "subscription",
        size: 352,
        cell: (cellInfo) => {
          const customer = cellInfo.row.original;
          const subscriptionCurrency = customer.subscriptions[0]?.currency;
          const subscriptionsCount = customer.subscriptions.length;
          const subscriptionsTotal = customer.subscriptions.reduce(
            (acc: number, currentValue: BillingCustomerSubscription): number =>
              acc + currentValue.totalPrice,
            0,
          );
          if (subscriptionsCount > 0) {
            return (
              <span className="text-gray-400 text-sm">
                {subscriptionsCount} Active for{" "}
                {/* TODO:: replace this with actual price from backend */}
                {formatCurrency(subscriptionsTotal, subscriptionCurrency)}/month
              </span>
            );
          }
          return <span className="text-gray-400 text-sm">N/A</span>;
        },
      },
      {
        id: "external_id",
        header: "Stripe ID",
        accessorKey: "external_id",
        size: 190,
        cell: (cellInfo) => {
          const id = cellInfo.row.original.externalId;
          return <ClipCopy data={id} className="w-full" truncate />;
        },
      },
      {
        id: "company_id",
        header: "Schematic ID",
        accessorKey: "company_id",
        size: 210,
        cell: (cellInfo) => {
          const customer = cellInfo.row.original;
          return customer.companyId ? (
            <div className="text-sm">
              <ClipCopy data={customer.companyId} />
            </div>
          ) : (
            <div className="text-sm">N/A</div>
          );
        },
      },
    ],
    [],
  );

  // const requestTypeOptions: AuditLogTableRequestOptions[] = [
  //   {
  //     label: "Failed",
  //     value: AuditLogTableRequest.All,
  //   },
  //   {
  //     label: "Imported",
  //     value: AuditLogTableRequest.App,
  //   },
  // ];

  const importedCount = getImportedCustomersCount.isSuccess
    ? getImportedCustomersCount.data.data.count
    : 0;

  return (
    <SettingsBox
      title={
        <div className="text-3xl font-normal">{`${importedCount} Customers Imported`}</div>
      }
      description="For any new customers, include the Stripe customer ID in any API request or event sent to Schematic to ensure entities are matched. "
      style="boxed"
      className="h-auto !pb-8"
    >
      {importedCount !== 0 && (
        <>
          <TableHeader
            headerText={"Imported customers"}
            searchPlaceholder="Search author, resource, request id, URL"
            onSearch={handleSearch}
            className="!mb-0"
          >
            {/*<ToggleButtonGroup*/}
            {/*  defaultValue={AuditLogTableRequest.App}*/}
            {/*  buttons={requestTypeOptions}*/}
            {/*  onToggleClick={setRequestTypeFilter}*/}
            {/*/>*/}
          </TableHeader>
          <InfiniteTable
            queryKey={["list-customers"]}
            queryFn={listCustomers}
            filter={filter}
            columns={columns}
          />
        </>
      )}

      {logOverlay && (
        <AuditLogOverlay id={logOverlay} onClose={() => setLogOverlay(null)} />
      )}
    </SettingsBox>
  );
};
