import { EntityTraitDefinitionResponseData } from "@models/api";
import { EntityType } from "@models/entityTrait";
import { Pill } from "@ui/Pill";

export const EntityTraitLabel = ({
  entityTrait,
}: {
  entityTrait: EntityTraitDefinitionResponseData;
}) => {
  const pillColor =
    entityTrait.entityType === EntityType.Company ? "blue" : "green";

  return (
    <div className="select-trait flex w-full overflow-y-auto justify-between py-1 px-1.5">
      <div className="flex flex-col">
        <div className="text-base">{entityTrait.hierarchy.join(".")}</div>
      </div>
      <div className="flex items-end flex-col">
        <Pill text="normal" className=" capitalize ml-4" color={pillColor}>
          {entityTrait.entityType}
        </Pill>
        <span className="text-sm mt-1 traits">{entityTrait.traitType}</span>
      </div>
    </div>
  );
};
