import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import useTablePagination from "@hooks/useTablePagination";
import { ListPlansParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { Plan, PlanType } from "@models/plan";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { countPlans, listPlans } from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { CellContext, ColumnDef, VisibilityState } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { PillGroup } from "@ui/PillGroup";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PlansBlankState } from "../blank-states/PlansBlankState";
import { PriceInfoBlock } from "../PriceInfoBlock";

export interface PlansTableProps {
  type: PlanType;
  onCreate?: () => void;
}

export const PlansTable = ({ type, onCreate }: PlansTableProps) => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const billingFlag = useSchematicFlag("billing", { fallback: true });
  const planConfigurationFlag = useSchematicFlag("plan-configuration", {
    fallback: true,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListPlansParams>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const planEditAllowed = usePermission(ClerkUserPermission.plans_edit);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<Plan>[]>(() => {
    return [
      {
        id: "plan",
        header: type === PlanType.Plan ? "Plan" : "Add On",
        accessorKey: "plan",
        size: 300,
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <Diamond style={cell.icon as DiamondStyleTypes} />
              <div className="flex flex-col ml-4">
                <div
                  className="font-medium text-[18px] !leading-none truncate-md"
                  title={cell.name}
                >
                  {cell.name}
                </div>
                <div className="leading-none text-sm text-gray-400">
                  {cell.id}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "features",
        header: "Features",
        accessorKey: "features",
        cell: (cellInfo) => {
          const { features } = cellInfo.row.original;

          return (
            <div className="flex flex-row items-center">
              <PillGroup items={features} limit={1} />
            </div>
          );
        },
      },
      ...(billingFlag
        ? [
            {
              id: "prices",
              header: "Prices",
              accessorKey: "prices",
              cell: (cellInfo: CellContext<Plan, unknown>) => {
                const plan = cellInfo.row.original;

                return (
                  <PriceInfoBlock
                    planId={plan.id}
                    items={plan.billingProduct?.prices || []}
                  />
                );
              },
            },
          ]
        : []),
      {
        id: "companies",
        header: "Companies",
        accessorKey: "companies",
        size: 160,
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <div className="leading-none text-gray-400">
              {cell.companyCount}
            </div>
          );
        },
      },
    ];
  }, [billingFlag, type]);

  const {
    countQuery,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<Plan, ListPlansParams>(
    ["plans", type],
    listPlans(type),
    countPlans(type),
    filter,
  );

  const plansHeaderText = useSecondaryTableHeader(
    "plans",
    countPlans(PlanType.Plan),
  );
  const addonsHeaderText = useSecondaryTableHeader(
    "add ons",
    countPlans(PlanType.AddOn),
  );

  const detailsLink = (row: Plan) => {
    return `${
      type === PlanType.Plan ? PlanRoutePaths.Plans : PlanRoutePaths.AddOns
    }/${row.id}`;
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>Create</>,
      color: "blue",
      disabled:
        !useSchematicFlag("plans", { fallback: true }) || !planEditAllowed,
      onClick: onCreate,
    },
  ];

  const noPlansCreated = countQuery?.data?.count === 0 && searchTerm === "";
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderPlansTable = () => {
    switch (true) {
      case noPlansCreated:
        return (
          <PlansBlankState onCreate={onCreate} disabled={!planEditAllowed} />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              detailsLink={detailsLink}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
            />
          )
        );
    }
  };

  return (
    <div className="pb-16">
      {!noPlansCreated && (
        <TableHeader
          headerTabs={[
            {
              active: type === PlanType.Plan,
              label: plansHeaderText,
              url: `/${environmentId}/${PlanRoutePaths.Plans}`,
            },
            {
              active: type === PlanType.AddOn,
              label: addonsHeaderText,
              url: `/${environmentId}/${PlanRoutePaths.AddOns}`,
            },
            ...(planConfigurationFlag
              ? [
                  {
                    label: "Configuration",
                    url: `/${environmentId}/${PlanRoutePaths.Configuration}`,
                  },
                ]
              : []),
          ]}
          searchPlaceholder={`Find ${
            type === PlanType.Plan ? "Plan" : "Add On"
          }`}
          onSearch={handleSearch}
          buttons={headerButtons}
        />
      )}
      {renderPlansTable()}
    </div>
  );
};
