import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { listPlansWithEntitlements } from "@modules/plans/queries/planConfiguration";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { PlanLabel } from "@ui/PlanLabel";
import { Form, Formik } from "formik";
import React from "react";

type PlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type FormValues = {
  plans: PlanGroupPlanDetailResponseData[];
};

type AddActivePlansOverlayProps = {
  selectedPlans: PlanGroupPlanDetailResponseData[];
  setActivePlans: (plans: PlanGroupPlanDetailResponseData[]) => void;
  onClose: () => void;
};

export const AddActivePlansOverlay = ({
  selectedPlans,
  setActivePlans,
  onClose,
}: AddActivePlansOverlayProps) => {
  const initialValues: FormValues = {
    plans: [],
  };

  const onSubmit = ({ plans }: FormValues) => {
    setActivePlans(plans);
    onClose();
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      <OverlayModal>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }) => (
            <Form className="flex flex-col">
              <div className="flex h-full flex-1 max-h-full overflow-hidden relative">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
                >
                  <Icon name="close" className="text-3xl" />
                </button>

                <div className="flex flex-col flex-1 px-12 py-12 h-full min-h-[632px] max-h-[750px]  max-w-full overflow-hidden overflow-y-scroll">
                  <div className="flex-1 overflow-y-auto">
                    <FormHeader
                      label="Live plans"
                      title="Choose Live Plans"
                      compact
                    />
                    <FormColumn>
                      <Alert style="yellow" size="xs" className="flex">
                        <div className="mr-3">
                          <Icon
                            name="exclamation-rounded-filled"
                            className="text-2xl leading-none text-yellow-300"
                          />
                        </div>
                        <div>
                          Only plans with a Stripe product and a yearly and
                          monthly price can be selected.
                        </div>
                      </Alert>
                      <FormikAsyncSelect
                        className="flex-1"
                        label="Plan"
                        name="planIds"
                        placeholder="Type to select plan..."
                        defaultOptions
                        isMulti
                        loadOptions={listPlansWithEntitlements}
                        loadOptionsMappers={{
                          mapperFunction: (plan) => ({
                            value: plan.id,
                            label: <PlanLabel plan={plan} font="normal" />,
                            entity: plan,
                          }),
                          requestFilter: {
                            hasProductId: true,
                          },
                        }}
                        selectedOption={selectedPlans.map((plan) => ({
                          value: plan.id,
                          label: <PlanLabel plan={plan} font="normal" />,
                          entity: plan,
                        }))}
                        onChange={(options: PlanOption[]) => {
                          setFieldValue(
                            "plans",
                            options.map(({ entity }) => entity),
                          );
                        }}
                      />
                    </FormColumn>
                  </div>
                  <div className="flex mt-6 items-end justify-end space-x-2">
                    <button
                      className="button button-sm button-blue"
                      type="submit"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
